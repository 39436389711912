import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { map } from "rxjs/operators";
import { JwtHelperService } from "@auth0/angular-jwt";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  ardemosapiKey: any;
  isLoggedin: boolean = false;
  userInfo: BehaviorSubject<any> = new BehaviorSubject(null);
  jwtHelper = new JwtHelperService();
  genTokenUrl: any;

  constructor(private http: HttpClient) {
    this.loadUserInfo();
    this.ardemosapiKey = environment.apiKeyardemos;
    this.genTokenUrl = environment.apiKeygenToken;
  }

  loadUserInfo() {
    let userdata = this.userInfo.getValue();
    if (!userdata) {
      const accesstoken = localStorage.getItem("access_token");
      if (accesstoken) {
        userdata = this.jwtHelper.decodeToken(accesstoken);
        this.userInfo.next(userdata);
        this.isLoggedin = true;
      }
    }
  }

  userLogin(logindata: any): Observable<boolean> {
    return this.http.post(this.ardemosapiKey + "login", logindata).pipe(
      map((value: any) => {
        if (value) {
          let date = Date.now() / 1000;
          var dateTime = date.toFixed();
          localStorage.setItem("access_token", value.token);
          localStorage.setItem("refresh_token", value.refresh_token);
          const decrypteduser = this.jwtHelper.decodeToken(value.token);
          localStorage.setItem("expiration", decrypteduser.exp);
          // const data ={
          //   access_token : value.token,
          //   refresh_token : value.refresh_token,
          //   tokenExpiration: decrypteduser.exp

          // }
          this.userInfo.next(decrypteduser);
          this.isLoggedin = true;
          return true;
        }
        return false;
      })
    );
  }

  public logout() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("refresh_token");
    localStorage.clear();
    this.isLoggedin = false;
  }

  isLoggedIn() {
    let data = localStorage.getItem("access_token");
    let refreshToken = localStorage.getItem("refresh_token");
    // console.log(refreshToken);
    // console.log("Local Storage", data);
    if (localStorage.getItem("access_token") == null) {
      this.isLoggedin = false;
      return this.isLoggedin;
    } else {
      return true;
    }
  }

  callrefreshToken(tokenpayload: any): Observable<any> {
    const body = { refresh_token: tokenpayload };
    return this.http.post(
      //"https://backend.immersionslabs.com/auth/gentoken",
      this.genTokenUrl,
      body
    );
  }
}
