import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";

import { FooterComponent } from "./footer/footer.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { ColorSwitcherComponent } from "./color-switcher/color-switcher.component";
import { Error500Component } from "./error-pages/error500/error500.component";
import { Error404Component } from "./error-pages/error404/error404.component";

@NgModule({
  exports: [
    CommonModule,
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorSwitcherComponent,
    NgbModule,
    Error500Component,
    Error404Component,
  ],
  imports: [RouterModule, CommonModule, NgbModule, PerfectScrollbarModule],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ColorSwitcherComponent,
    Error500Component,
    Error404Component,
  ],
  providers: [],
})
export class SharedModule {}
