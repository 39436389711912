import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { filter } from "rxjs";
import { SidebarService } from "../../shared/sidebar/sidebar.service";

@Component({
  selector: "app-full-layout",
  templateUrl: "./full-layout.component.html",
})
export class FullLayoutComponent implements OnInit {
  current_Route: any = [];
  navBar_hide: boolean = false;
  constructor(public sidebarservice: SidebarService, private router: Router) {
    this.router.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentRoute = event.url.split("/");
        //console.log("split url fl",this.currentRoute);
        if (this.currentRoute[1] == "analytics") {
          this.sidebarHide = false;
          console.log("this.sidebarHide", this.sidebarHide);
        }
      });
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  currentRoute: any;
  sidebarHide: boolean = true;
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
