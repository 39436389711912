import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { Observable } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "./auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  jwtHelper = new JwtHelperService();
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var userdata = this.authService.userInfo.getValue();

    if (req.url.indexOf("/gentoken") > -1) {
      return next.handle(req);
    }
    const access_token = localStorage.getItem("access_token");
    if (access_token) {
      const expiration = localStorage.getItem("expiration");
      if (Date.now() < Number(expiration) * 1000) {
        const newReq = req.clone({
          headers: req.headers.set("Authorization", `Bearer ${access_token}`),
        });
        return next.handle(newReq);
      }
      const tokenPayload = localStorage.getItem("refresh_token");
      return this.authService.callrefreshToken(tokenPayload).pipe(
        switchMap((newTokenpayload: any) => {
          localStorage.setItem("access_token", newTokenpayload.token);
          localStorage.setItem("refresh_token", newTokenpayload.refresh_token);
          const decrypteduser = this.jwtHelper.decodeToken(
            newTokenpayload.token
          );
          this.authService.userInfo.next(decrypteduser);
          const newReq = req.clone({
            headers: req.headers.set(
              "Authorization",
              `Bearer ${newTokenpayload.token}`
            ),
          });
          return next.handle(newReq);
        })
      );
    }
    return next.handle(req);
  }
}
