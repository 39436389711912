<!--start header -->
<header>
  <div class="topbar d-flex align-items-center">
    <nav class="navbar navbar-expand">
      <div class="mobile-toggle-menu">
        <i (click)="toggleSidebar()" class="bx bx-menu"></i>
      </div>
      <div class="search-bar flex-grow-1">
        <div class="position-relative search-bar-box">
          <!-- <input type="text" class="form-control search-control" placeholder="Type to search..."> <span
            class="position-absolute top-50 search-show translate-middle-y"><i class="bx bx-search"></i></span>
          <span class="position-absolute top-50 search-close translate-middle-y"><i class="bx bx-x"></i></span> -->
        </div>
      </div>
      <div class="top-menu ms-auto"></div>
      <div class="user-box dropdown">
        <a
          class="d-flex align-items-center nav-link dropdown-toggle dropdown-toggle-nocaret"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false">
          <img
            src="assets/images/logo-icon.png"
            class="user-img"
            alt="user avatar" />
          <div class="user-info ps-3">
            <p class="user-name mb-0">Immersion Software Labs</p>
            <p class="designattion mb-0">Admin</p>
          </div>
        </a>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a class="dropdown-item" routerLink="/user-profile">
              <i class="bx bx-user"></i><span>Profile</span>
            </a>
          </li>
          <li>
            <a class="dropdown-item cursor-pointer" (click)="userogout()">
              <i class="bx bx-log-out-circle"></i><span>Logout</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>
<!--end header -->
