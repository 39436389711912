import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";

import { FullLayoutComponent } from "./layouts/full/full-layout.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";

import { Full_ROUTES } from "./shared/routes/full-layout.routes";
import { CONTENT_ROUTES } from "./shared/routes/content-layout.routes";
import { AuthRouteGuard } from "./shared/guard/auth.route.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "auth/sign-in",
    pathMatch: "full",
  },
  // {
  //   path: "",
  //   redirectTo: "auth/sign-in",
  //   pathMatch: "full",
  // },
  {
    path: "",
    component: FullLayoutComponent,
    children: Full_ROUTES,
    canActivate: [AuthRouteGuard],
  },
  {
    path: "",
    component: ContentLayoutComponent,
    children: CONTENT_ROUTES,
    canActivate: [AuthRouteGuard],
  },
  // {
  //   path: "analytics",
  //   loadChildren: () =>
  //     import("./analytics/analytics.module").then((a) => a.AnalyticsModule),
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
