import { Component, OnInit } from "@angular/core";
import { ROUTES } from "./sidebar-routes.config";
import {
  Router,
  Event,
  NavigationStart,
  NavigationEnd,
  NavigationError,
} from "@angular/router";
import { filter } from "rxjs";
import { SidebarService } from "./sidebar.service";

import * as $ from "jquery";

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
})
export class SidebarComponent implements OnInit {
  side_Menu_Hide: boolean = false;
  current_Route: any;

  // public menuItems: any[];
  w_width = window.innerWidth;
  constructor(public sidebarservice: SidebarService, private router: Router) {
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
      }
      if (
        event instanceof NavigationEnd &&
        this.w_width < 1025 &&
        (document.readyState == "complete" || false)
      ) {
        this.toggleSidebar();
        // Hide loading indicator
      }
      if (event instanceof NavigationError) {
        // Hide loading indicator
        // Present error to user
        console.log(event.error);
      }
    });
  }

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());

    if ($(".wrapper").hasClass("nav-collapsed")) {
      // unpin sidebar when hovered
      $(".wrapper").removeClass("nav-collapsed");
      $(".sidebar-wrapper").unbind("hover");

      $(".logoIcon").addClass("logo-icon45");
      $(".logoIcon").removeClass("logo-icon100");
    } else {
      $(".wrapper").addClass("nav-collapsed");
      $(".sidebar-wrapper").hover(
        function () {
          $(".wrapper").addClass("sidebar-hovered");

          $(".logoIcon").removeClass("logo-icon45");
          $(".logoIcon").addClass("logo-icon100");
        },
        function () {
          $(".wrapper").removeClass("sidebar-hovered");

          $(".logoIcon").addClass("logo-icon45");
          $(".logoIcon").removeClass("logo-icon100");
        }
      );
    }
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  value: any;
  ngOnInit() {
    // this.menuItems = ROUTES.filter((menuItem) => menuItem);
    $.getScript("./assets/js/app-sidebar.js");

    // this.sidebarservice.SharingData.subscribe((data: any) => {
    //   this.value = data;
    // });
  }

  // GoTo(item: number) {
  //   this.sidebarservice.scrollPage(item);
  // }

  scrollToTop() {
    document.getElementById("toTop")?.scrollIntoView({ behavior: "smooth" });
  }

  scrollToSurfaceDetection() {
    document
      .getElementById("surfaceDetection")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  scrollToImageDetection() {
    console.log("ImageDetection");
    document
      .getElementById("ImageDetection")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  scrollToOsDetection() {
    document
      .getElementById("OsDetection")
      ?.scrollIntoView({ behavior: "smooth" });
  }
}
