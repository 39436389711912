import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-error500",
  templateUrl: "./error500.component.html",
  styles: [],
})
export class Error500Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
