<!-- Sidebar Content starts -->
<div class="sidebar sidebar-wrapper">
  <!-- Sidebar Header starts -->
  <div class="sidebar-header">
    <div *ngIf="!side_Menu_Hide" routerLink="/" class="cursor-pointer">
      <img
        src="assets/images/company_logo_b.png"
        class="logoIcon logo-icon"
        alt="logo icon" />
    </div>
    <!-- <div *ngIf="!side_Menu_Hide" routerLink="/" class="cursor-pointer">
      <h4 class="logo-text">ISL</h4>
    </div> -->
    <div *ngIf="!side_Menu_Hide" class="toggle-icon ms-auto">
      <i (click)="toggleSidebar()" class="bx bx-arrow-to-left"></i>
    </div>
  </div>
  <!-- Sidebar Header Ends -->
  <div class="sidebar-content" [perfectScrollbar]>
    <ul *ngIf="!side_Menu_Hide" class="navigation">
      <!-- First level menu -->
      <li class="sub">
        <a routerLink="dashboard/default">
          <i class="bx bx-home-circle"></i>
          <span class="menu-title cursor-default">Dashboard</span>
        </a>
      </li>
      <li class="sub">
        <a routerLink="dashboard/website">
          <i class="lni lni-laptop"></i>
          <span class="menu-title">Website</span>
        </a>
        <!-- Second level menu -->
        <ul class="menu-content">
          <li class="sub">
            <a [routerLink]="[]">
              <i class="lni lni-graduation"></i>
              <span class="sub-menu-title">Careers</span>
            </a>
            <!-- Third level menu -->
            <ul class="menu-content">
              <li>
                <a routerLink="dashboard/job-list">
                  <i class="lni lni-zoom-in"></i>
                  <span class="sub-menu-title">Jobs</span>
                </a>
              </li>
              <li>
                <a routerLink="dashboard/applicant-job-list">
                  <i class="lni lni-users"></i>
                  <span class="sub-menu-title">Applicants</span>
                </a>
              </li>
            </ul>
          </li>
          <li class="">
            <a routerLink="dashboard/contact-list">
              <i class="fadeIn animated bx bx-user-pin"></i>
              <span class="sub-menu-title">Contact us</span>
            </a>
          </li>
          <li class="">
            <a routerLink="dashboard/our-services">
              <i class="lni lni-comments-alt"></i>
              <span class="sub-menu-title">Services</span>
            </a>
          </li>
          <li class="">
            <a routerLink="dashboard/all-testimonials">
              <i class="lni lni-comments-alt"></i>
              <span class="sub-menu-title">Testimonials</span>
            </a>
          </li>
          <li class="">
            <a routerLink="dashboard/aboutus">
              <i class="lni lni-cogs"></i>
              <span class="sub-menu-title">About Us</span>
            </a>
          </li>
          <li class="">
            <a routerLink="dashboard/teams-list">
              <i class="lni lni-network"></i>
              <span class="sub-menu-title">Teams</span>
            </a>
          </li>
          <li class="">
            <a routerLink="dashboard/contributors">
              <i class="lni lni-users"></i>
              <span class="sub-menu-title">Contributors</span>
            </a>
          </li>
          <li class="">
            <a routerLink="dashboard/blogs">
              <i class="lni lni-comments-alt"></i>
              <span class="sub-menu-title">Blogs</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="sub">
        <a routerLink="dashboard/ardemos">
          <i class="bx bx-diamond"></i>
          <span class="menu-title">AR Demos</span>
        </a>
        <!-- Second level menu -->
        <ul class="menu-content">
          <li>
            <a routerLink="dashboard/twod-demos">
              <i class="bx bx-diamond"></i>
              <span class="sub-menu-title">Hybrid Demos</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/threed-demos">
              <i class="bx bx-diamond"></i>
              <span class="sub-menu-title">3D Demos (Multi)</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/model-list">
              <i class="bx bx-diamond"></i>
              <span class="sub-menu-title">3D Demos (Mono)</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/usecases">
              <i class="bx bx-diamond"></i>
              <span class="sub-menu-title">UseCases</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/demos-projects">
              <i class="bx bx-diamond"></i>
              <span class="sub-menu-title">Projects</span>
            </a>
          </li>
          <!-- <li>
            <a routerLink="dashboard/create-mind">
              <i class="bx bx-diamond"></i>
              <span class="sub-menu-title">Create Mind</span>
            </a>
          </li> -->
          <!-- <li>
            <a routerLink="dashboard/modify-asset-axis">
              <i class="bx bx-diamond"></i>
              <span class="sub-menu-title">Assets Cordinates</span>
            </a>
          </li> -->
          <li>
            <a routerLink="dashboard/demo-booking-list">
              <i class="fadeIn animated bx bx-heart-circle"></i>
              <span class="sub-menu-title">Demo Bookings</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/demos-list">
              <i class="lni lni-comments"></i>
              <span class="sub-menu-title">Feedbacks</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="sub">
        <a routerLink="dashboard/users-dashboard">
          <i class="bx bx-user-circle"></i>
          <span class="menu-title cursor-default">User<br />Management</span>
        </a>
        <!-- Second level menu -->
        <ul class="menu-content">
          <li>
            <a routerLink="dashboard/total-users">
              <i class="lni lni-users"></i>
              <span class="sub-menu-title">All Users</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="sub">
        <a routerLink="dashboard/shop">
          <i class="bx bx-shopping-bag"></i>
          <span class="menu-title cursor-default">Shop<br />Management</span>
        </a>
        <ul class="menu-content">
          <li>
            <a routerLink="dashboard/products">
              <i class="bx bxs-cube"></i>
              <span class="sub-menu-title">All Products</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/sales">
              <i class="bx bxs-cube"></i>
              <span class="sub-menu-title">All Sales</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/publishers">
              <i class="bx bxs-cube"></i>
              <span class="sub-menu-title">All Publishers</span>
            </a>
          </li>
          <li>
            <a routerLink="dashboard/shop-footer">
              <i class="bx bxs-cube"></i>
              <span class="sub-menu-title">Footer</span>
            </a>
          </li>
        </ul>
      </li>
      <li class="">
        <a routerLink="dashboard/subscriber-list">
          <i class="fadeIn animated bx bx-envelope-open"></i>
          <span class="menu-title">Subscribers</span>
        </a>
      </li>
    </ul>
  </div>
  <!-- Sidebar Content Ends -->
</div>
