import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";
import { AuthService } from "../services/auth.service";
import { SidebarService } from "../sidebar/sidebar.service";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
})
export class NavbarComponent implements OnInit {
  isAuthenticated!: boolean;
  constructor(
    public sidebarservice: SidebarService,
    private route: Router,
    public auth: AuthService
  ) {}

  toggleSidebar() {
    this.sidebarservice.setSidebarState(!this.sidebarservice.getSidebarState());
  }

  getSideBarState() {
    return this.sidebarservice.getSidebarState();
  }

  hideSidebar() {
    this.sidebarservice.setSidebarState(true);
  }

  current_Route: any;
  navBar_Hide: boolean = false;
  ngOnInit() {
    /* Search Bar */
    $(document).ready(function () {
      $(".mobile-search-icon").on("click", function () {
        $(".search-bar").addClass("full-search-bar");
      }),
        $(".search-close").on("click", function () {
          $(".search-bar").removeClass("full-search-bar");
        });
    });

    // //To Hide Nav bar
    // this.route.events
    //   .pipe(filter((event: any) => event instanceof NavigationEnd))
    //   .subscribe((event: any) => {
    //     this.current_Route = event.url.split('/');
    //     console.log("split url",this.current_Route);
    //     if(this.current_Route[1] === 'analytics'){
    //       this.navBar_Hide = true;
    //     }
    //   });
  }
  userogout() {
    this.auth.logout();
    this.route.navigate(["/auth/sign-in"]);
    window.location.reload();
  }
  isLoggedIn() {
    this.isAuthenticated = this.auth.isLoggedIn();
  }
}
