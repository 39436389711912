import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SidebarService {
  toggled = false;
  // private_item: Number = 0;
  // SharingData = new Subject();

  constructor() {}

  toggle() {
    this.toggled = !this.toggled;
  }

  getSidebarState() {
    return this.toggled;
  }

  setSidebarState(state: boolean) {
    this.toggled = state;
  }

  // scrollPage(item: Number) {
  //   this.SharingData.next(item);
  // }
}
