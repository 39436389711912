<!--Start Back To Top Button-->
<a href="#" (click)="$event.preventDefault()" class="back-to-top"
  ><i class="bx bxs-up-arrow-alt"></i
></a>
<!--End Back To Top Button-->
<!--footer -->
<footer class="page-footer">
  <p class="mb-0">
    Copyright © Immersion Software Labs Private Limited
    {{ currentYear.getFullYear() }}. All right reserved. {{ branch }}
  </p>
</footer>
<!-- end footer -->
