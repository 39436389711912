import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthRouteGuard implements CanActivate {
  constructor(private authService: AuthService, private routes: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    const userData = this.authService.userInfo.getValue();
    // console.log("Route Guards user data",state.url)
    if (userData) {
      if (state.url.indexOf("auth/sign-in") > -1) {
        this.routes.navigate(["/dashboard/default"]);
        return false;
      }
    } else {
      if (state.url.indexOf("dashboard") > -1) {
        this.routes.navigate(["/auth/sign-in"]);
        return false;
      }
    }
    return true;
  }
}
