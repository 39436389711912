import { Component, OnInit } from "@angular/core";
import { NavigationEnd } from "@angular/router";
import * as $ from "jquery";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
})
export class FooterComponent implements OnInit {
  branch: any;

  currentYear = new Date();

  ngOnInit() {
    if (environment.production == true) {
      this.branch = "[M]";
    } else {
      this.branch = "[T]";
    }
    /* Back To Top */

    $(document).ready(function () {
      $(window).on("scroll", function () {
        if (event instanceof NavigationEnd) {
          $(".back-to-top").fadeIn();
        } else {
          $(".back-to-top").fadeOut();
        }
      });

      $(".back-to-top").on("click", function () {
        $("html, body").animate({ scrollTop: 0 }, 600);
        return false;
      });
    });
  }
}
